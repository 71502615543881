const product = {
  add_tags: 'Aggiungi i codici prodotto',
  blister: 'Blister',
  cpa: 'anno in corso',
  cpa_1m: '1 mese',
  cpa_12m: '12 mesi (rolling)',
  cpa_3m: '3 mesi',
  cpa_all: 'anno in corso + precendente',
  cpa_prev: 'anno precendente',
  package: 'Confezione',
  pallet: 'Pallet',
  search_codes: 'Cerca tra i codici prodotto',
  qta_min_handling: 'Quantità minima di handling',
  qta_min: 'Quantità minima in acquisto',
  qta_min_sell: 'Quantità minima in vendita',
}

export default product
