import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'
import useRouteParser from 'src/hooks/routeParser'
import { toInteger } from 'lodash'

const { t } = useI18n()
const { getPaginationCasts, getPaginationInject, toBooleanIndeterminable } =
  useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'suppliers',
  name: 'suppliers',
  redirect: () => ({ name: 'suppliersIndex' }),
  meta: {
    auth: {
      permission: 'supplier_show',
    },
  },

  children: [
    {
      path: '',
      name: 'suppliersIndex',
      component: () =>
        import(
          /* webpackChunkName: "suppliers-index" */ 'pages/supplier/IndexPage.vue'
        ),
      meta: {
        breadcrumb: {
          name: t('breadcrumb.suppliers.index'),
          icon: 'mdi-land-rows-horizontal',
        },
        inject: {
          query: {
            ...getPaginationInject(),
            active: true,
            sort: 'code',
          },
        },
        casts: {
          query: {
            ...getPaginationCasts(),
            active: toBooleanIndeterminable,
            car_body: toBooleanIndeterminable,
          },
        },
      },
      children: [
        {
          path: ':id',
          name: 'suppliersShow',
          component: () =>
            import(
              /* webpackChunkName: "suppliers-show" */ 'pages/supplier/ShowPage.vue'
            ),
          redirect: () => ({ name: 'suppliersEdit' }),
          meta: {
            auth: {
              permission: 'supplier_manage',
            },
            breadcrumb: {
              icon: 'mdi-truck-outline',
              name: t('breadcrumb.suppliers.show'),
            },
            casts: {
              params: {
                id: toInteger,
              },
            },
          },
          children: [
            {
              path: 'edit',
              name: 'suppliersEdit',
              component: () =>
                import(
                  /* webpackChunkName: "suppliers-edit" */ 'pages/supplier/EditPage.vue'
                ),
              meta: {
                breadcrumb: {
                  icon: 'mdi-pencil',
                  name: t('breadcrumb.suppliers.edit'),
                },
              },
            },
          ],
        },
      ],
    },
  ],
}

export default module