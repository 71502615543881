const carsdata = {
  models: 'Modelli auto',
  brands: 'Marche auto',
  hint: 'Aggiungi le marche auto (separate da una virgola)',
  modelhint: 'Aggiungi i modelli auto (separati da virgola)',
  open_dialog_text: 'Crea nuovo modello auto',
  model: 'Modello auto',
  brand: 'Marca auto',
  model_hint: 'Crea nuovo modello auto',
  brand_hint: 'Crea nuovo marca auto',
}

export default carsdata
