const class_merch = {
  clusters: {
    confirm_remove: 'Eliminare il cluster "{name}"?',
    new: 'Nuovo cluster',
    new_tooltip: 'Aggiungi nuovo cluster',
    show_all_classes: 'Mostra il dettaglio completo delle {number} classi',
    year_update_tooltip:
      "Non è possibile modificare l'anno di un cluster esistente",
  },
}

export default class_merch
