import { defineStore } from 'pinia'
import {
  ActionTypes,
  Actions,
  Getters,
  Namespace,
  State,
} from 'src/types/stores/core'

export const useCoreStore = defineStore<Namespace, State, Getters, Actions>(
  'core',
  {
    state: () => ({
      ajaxLoading: false,
      ajaxPendings: 0,
      dirty: false,
      ready: false,
      settings: undefined,
    }),
    getters: {},
    actions: {
      [ActionTypes.ADD_AJAX_PENDING]() {
        this.ajaxPendings++
        this.ajaxLoading = true
      },
      [ActionTypes.REMOVE_AJAX_PENDING]() {
        this.ajaxPendings--
        this.ajaxLoading = this.ajaxPendings > 0
      },
      [ActionTypes.SET_DIRTY](dirty) {
        this.dirty = dirty
      },
      [ActionTypes.SET_READY]() {
        this.ready = true
      },
      [ActionTypes.SET_SETTINGS](settings) {
        this.settings = settings ?? <State['settings']>{}
      },
    },
  }
)
