import { pick } from 'lodash'
import {
  RouteLocationNormalizedArgumented,
  RouteLocationNormalizedLoadedArgumented,
} from 'src/types/route'
import {
  computed,
  watch,
  Ref,
  WatchCallback,
  WatchOptions,
  onUnmounted,
} from 'vue'

import {
  useRouter,
  LocationQuery,
  RouteRecordName,
  RouteParams,
} from 'vue-router'

type RouteUpdateArguments<Q = LocationQuery, P = RouteParams> = {
  name?: RouteRecordName
  query?: Partial<Q>
  params?: Partial<P>
}

interface WatchSettings {
  /**
   * Watch also childred
   */
  deep: boolean
}

export default function useWatchRoute<Q = LocationQuery, P = RouteParams>(
  page = '*',
  handle?:
    | WatchCallback<
        RouteLocationNormalizedLoadedArgumented<Q, P>,
        RouteLocationNormalizedLoadedArgumented<Q, P> | undefined
      >
    | undefined,
  options?: WatchOptions,
  settings?: WatchSettings
) {
  const watcherSettings = { deep: false, ...settings }

  const router = useRouter()
  const route = router.currentRoute as Ref<
    RouteLocationNormalizedLoadedArgumented<Q, P>
  >

  const query = computed(() => route.value.query)
  const params = computed(() => route.value.params)

  function isPage(name: string): boolean {
    if (name === '*') true

    return router.currentRoute.value.name == name
  }

  const isCurrentPage = computed(() => isPage(page))

  if (handle) {
    const unwatch = watch(
      () => route.value,
      (to, from, onUnvalidated) => {
        if (!isCurrentPage.value) {
          if (
            !watcherSettings.deep ||
            !to.matched.find(({ name }) => page == name)
          )
            return
        }

        handle(to, from, onUnvalidated)
      },
      options
    )

    onUnmounted(() => unwatch())
  }

  function routeUpdate(
    data: RouteUpdateArguments<Q, P>,
    encode = true,
    replaceCurrent = false
  ): void {
    const query = { ...route.value.query, ...data.query }
    const params = { ...route.value.params, ...data.params }

    const routeData = encode
      ? routeDataEncode(query, params)
      : { query, params }

    router[replaceCurrent ? 'replace' : 'push']({
      query: routeData.query,
      params: routeData.params,
      name: data.name,
    }).catch(() => void 0)
  }

  function routeUpdateTS(
    data?: RouteUpdateArguments<Q, P>,
    encode = true,
    replaceCurrent = false
  ): void {
    const routeData = {
      query: {
        ts: new Date().getTime().toString(),
      },
      ...data,
    } as unknown as RouteUpdateArguments<Q, P>

    routeUpdate({ ...routeData }, encode, replaceCurrent)
  }

  function routeReplace(data: RouteUpdateArguments<Q, P>, encode = true): void {
    routeUpdate(data, encode, true)
  }

  function routeReplaceTS(
    data?: RouteUpdateArguments<Q, P>,
    encode = true
  ): void {
    routeUpdateTS(data, encode, true)
  }

  //encode route by configuration meta.encode
  function routeDataEncode(providedQuery: Q, providedParams: P) {
    const encoders = route.value.meta.encode ?? {}

    const query = {
      ...providedQuery,
    } as unknown as RouteLocationNormalizedArgumented['query']
    const params = {
      ...providedParams,
    } as unknown as RouteLocationNormalizedArgumented['params']

    if (query && encoders.query) {
      for (const field in encoders.query) {
        const value = Object.values(pick(query, field)).pop()

        if (value !== undefined) {
          const method = encoders.query[field]
          query[field] = method(value)
        }
      }
    }

    return { query, params } as {
      query: LocationQuery & Q
      params: RouteParams & P
    }
  }

  return {
    isCurrentPage,
    isPage,
    params,
    query,
    route,
    routeDataEncode,
    routeReplace,
    routeReplaceTS,
    routeUpdate,
    routeUpdateTS,
    router,
  }
}
