import { api } from 'src/boot/axios'
import { AuthResponse } from 'src/types/api/auth'

const END_POINT = 'user'

const Auth = {
  csrf: async () => {
    await api.get('csrf-cookie')
  },

  user: async (): Promise<AuthResponse> => {
    return await api.get(END_POINT)
  },

  logout: async (): Promise<void> => {
    await api.get('logout').catch(() => void 0)
  },
}

export default Auth
