const supplier = {
  car_body: 'Carrozzeria',
  promo: {
    application: 'Applicazione',
    applications: {
      none: 'Nessuna',
      cost: 'Costo ultimo',
      nc: 'Nota di credito',
    },
    confirm_remove: 'Eliminare la promozione "{label}"?',
    main_data: 'Dati generali',
    new: 'Nuova promozione',
    new_tooltip: 'Aggiungi nuova promozione',
    reward: 'Valore',
    reward_type: 'Tipo',
    reward_type_net_cost: 'Netto su prodotto/i',
    reward_type_percentage: 'Percentuale',
    reward_type_fixed_value: 'Promozione in EURO',
    target_data: 'Definizione target',
    target_max: 'Massimo',
    target_min: 'Minimo',
    target_type: 'Tipo',
    target_type_revenue: 'Fatturato',
    target_type_quantity: 'Quantità',
    value_data: 'Definizione premio',
    value_limit: 'Definizione limiti',
    value_limit_info:
      'Se non si impostano limiti la promozione sarà applicata a tutti i codici del fornitore selezionato',
  },
}

export default supplier
