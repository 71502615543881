import script from "./AppFooter.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppFooter.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QFooter,QBtn,QIcon,QTooltip});
