import { isEmpty, isObject, mapValues, pickBy } from 'lodash'
import { MetaPaginated } from 'src/types/api'
import { Ref, ref } from 'vue'

export type JsonValue =
  | {
    [key: string]: JsonValue | OrderItem | Record<string, number>
  }
  | Record<string, string | number>

export type OrderItem = {
  [s: string]: string | number | null
}

/**
 * Parse a value to bool
 *
 * @param  boolean | string | number value
 * @return bool
 */
export function parseBool(value: boolean | string | number): boolean {
  return value === true || value == 'true'
}

/**
 * Format currency
 *
 * @param  number | undefined value
 * @param  number precision
 * @return string
 */
export function formatCurrency(
  value: number | null | undefined,
  precision = 2
): string {
  if (!!value && value != 0) {
    const val = (value / 1).toFixed(precision).replace('.', ',')
    return '€' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  } else {
    return '-'
  }
}

/**
 * Format percentage
 *
 * @param  number value
 * @param  int round
 * @param  boolean real
 */
export function formatPerc(value: number, round = 0, real = false): string {
  return (
    new String(value < 100 || real ? (value / 1).toFixed(round) : 100).replace(
      '.',
      ','
    ) + '%'
  )
}

/**
 * Remove null or empty values form object
 */
export function objectClear<Input extends Record<string, unknown>>(
  input: Input,
  preserve?: string[]
): Partial<Input> {
  return pickBy<Input>(input, (value, name) => {
    return (
      preserve?.includes(name) ||
      (value != '' &&
        value != null &&
        (!Array.isArray(value) || value.length > 0) &&
        (!isObject(value) || !isEmpty(value)))
    )
  })
}

/**
 * Convert undefined values of provided object to 'undefined' string
 */
export function undefinedToString<Input extends Record<string, unknown>>(
  input: Input,
  only?: Array<keyof Input>
): { [P in keyof Input]: Input[keyof Input] | 'undefined' } {
  return mapValues(input, (value, name) => {
    if (
      value === undefined &&
      (only === undefined || only.length == 0 || only?.includes(name))
    ) {
      return 'undefined'
    }

    return value
  })
}

/**
 * Return default pagination meta object
 * According to Laravel Resouce pagination "meta"
 * @see https://laravel.com/docs/pagination#converting-results-to-json
 */
export function defaultMetaPaginationObject(): MetaPaginated {
  return {
    current_page: 0,
    from: 0,
    last_page: 0,
    per_page: 0,
    to: 0,
    total: 0,
  }
}

export function formatToDateLocaleString(
  value: string,
  dateOnly?: boolean
): string {
  if (dateOnly) return new Date(Date.parse(value)).toLocaleDateString()
  return new Date(Date.parse(value)).toLocaleString()
}

export function getRefJsonPropertyFromLocalStorage<Data extends object>(key: string): {
  prop: Ref<Data>
  setPropValue: (value: Data) => void
} {
  const storedValue: string | null = localStorage.getItem(key)
  let initialValue: Data = {} as Data

  if (storedValue) {
    initialValue = JSON.parse(storedValue) as Data
  }

  const prop: Ref<Data> = ref(initialValue) as Ref<Data>

  const updateLocalStorage = (value: Data) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  const setPropValue = (value: Data) => {
    prop.value = value
    updateLocalStorage(prop.value)
  }

  return { prop, setPropValue }
}

export function removeLocalStorageItem(key: string): void {
  localStorage.removeItem(key)
}
/**
 * Sum all occurences of property 'key' in an object
 *
 * @param obj
 * @param key
 * @returns
 */
export function sumValues<Data extends object>(obj: Data, key: string): number | undefined {
  if (obj === undefined) {
    return undefined
  }

  const values: (number | undefined)[] = toFlatArrayByKey(obj, key)

  const ret: number | undefined = values.reduce((acc, value) => {
    const innerSum = typeof value === 'number' ? value : 0
    return acc ? acc + innerSum : innerSum
  }, 0)

  return ret
}
/**
 * Reduces all occurencies of property in a deeply
 * nested object to a flat array
 *
 * @param obj
 * @param key
 * @returns (number | undefined)[]
 */
export function toFlatArrayByKey<Data extends object>(
  obj: Data,
  key: string
): (number | undefined)[] {
  const values: (number | undefined)[] = []

  Object.values(obj).forEach((innerObj) => {
    if (typeof innerObj === 'object' && innerObj !== null) {
      Object.values(innerObj as { [s: string]: unknown }).forEach((leafObj) => {
        const value = (leafObj as Record<string, number>)[key]
        values.push(typeof value === 'number' ? value : undefined)
      })
    }
  })

  return values
}
/**
 * Sums all values in an object given a key to search them,
 * but preserves the root key of any object's branch
 *
 * @param obj
 * @param key
 * @returns
 */
export function getSumValuesWithKey(
  obj: JsonValue,
  key: string
): { [key: string]: number } {
  const result: { [key: string]: number } = {}

  Object.entries(obj).forEach(([parentKey, innerObj]) => {
    if (typeof innerObj === 'object' && innerObj !== null) {
      Object.values(innerObj as { [s: string]: unknown }).forEach((leafObj) => {
        const sum = (leafObj as Record<string, number>)[key] ?? 0
        result[parentKey] = (result[parentKey] || 0) + sum
      })
    }
  })

  return result
}

export function sumValuesIf<Data extends object>(
  obj: Data,
  key: string,
  check: string
): number | undefined {
  const values: (number | undefined)[] = []

  Object.values(obj).forEach((innerObj) => {
    if (typeof innerObj === 'object' && innerObj !== null) {
      Object.values(innerObj as { [s: string]: unknown }).forEach((leafObj) => {
        const value = (leafObj as Record<string, number>)[key]
        const checkValue = (leafObj as Record<string, number>)[check]
        values.push(typeof value === 'number' ? value * checkValue : undefined)
      })
    }
  })

  return values.reduce((acc, value) => (acc || 0) + (value || 0), 0)
}

export function getSumValuesWithKeyIf<Data extends object>(
  obj: Data,
  key: string,
  check: string
): { [key: string]: number } {
  const result: { [key: string]: number } = {}

  Object.entries(obj).forEach(([parentKey, innerObj]) => {
    if (typeof innerObj === 'object' && innerObj !== null) {
      Object.values(innerObj as { [s: string]: unknown }).forEach((leafObj) => {
        const sum = (leafObj as Record<string, number>)[key] ?? 0
        const checkValue = (leafObj as Record<string, number>)[check] ?? 0
        if (checkValue !== 0) { // keep results only if check > 0
          result[parentKey] = (result[parentKey] || 0) + sum * checkValue
        }
      })
    }
  })

  return result
}
