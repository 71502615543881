import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'
import useRouteParser from 'src/hooks/routeParser'
import { toInteger } from 'lodash';
const { getPaginationCasts, toArrayOfInt, toFloat } =
  useRouteParser()

const { t } = useI18n()

const module: RouteRecordRawArgumented = {
  path: 'traders',
  name: 'traders',
  redirect: () => ({ name: 'tradersIndex' }),
  children: [
    {
      path: ':step?',
      name: 'tradersIndex',
      component: () =>
        import(
          /* webpackChunkName: "traders-index" */  'pages/traders/IndexPage.vue'
        ),
      meta: {
        breadcrumb: {
          name: t('breadcrumb.traders.index'),
          icon: 'mdi-registered-trademark',
        },
        auth: {
          permission: 'traders_products_create',
        },
        inject: {
          params: {
            step: 'select',
          },
        },
        casts: {
          query: {
            ...getPaginationCasts(),
            warehouse: toArrayOfInt,
            research_keys: toArrayOfInt,
            threshold: toInteger,
            increase: toFloat
          },
        },
      },
    },
  ],
}

export default module