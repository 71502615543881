import { RouteRecordRawArgumented } from 'src/types/route'

const module: RouteRecordRawArgumented = {
  path: '',
  name: 'index',
  component: () =>
    import(/* webpackChunkName: "index" */ 'pages/IndexPage.vue'),
  meta: {
    old: 'dashboard',
  },
}

export default module
