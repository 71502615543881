import useRouteParser from 'src/hooks/routeParser'
import { forEach, keyBy, map, mapValues } from 'lodash'
import {
  OrderBotFabXConfig,
  OrderBotFabXQuery,
  PackageSize,
  StockConfig,
  StockQuery,
  DiscountConfig,
  DiscountQuery,
  FiveThresholdsConfig,
} from 'src/types/api/order-bot'
import { WarehouseId } from 'src/types/api/warehouse'
import { RouteParamValue, RouteRecordRawArgumented } from 'src/types/route'

const { toArray, toArrayOfInt, toBoolean, toInt, toObject } = useRouteParser()

const module: RouteRecordRawArgumented = {
  path: ':step?',
  name: 'orderBotCompose',
  component: () =>
    import(
      /* webpackChunkName: "order-bot-compose" */ 'pages/order-bot/ComposePage.vue'
    ),
  meta: {
    casts: {
      query: {
        classMerch: toArrayOfInt,
        fabX: (value) => {
          const {
            d: to,
            ico: ignore_customers_orders,
            t: types,
            o,
            l: from_tolerance,
          } = toObject<OrderBotFabXQuery>(value)

          const from: Partial<OrderBotFabXConfig['from']> = {}
          o.map((item) => {
            from[item[0]] = {
              cpa: item[1],
              quantity: item[2],
              active: true,
            }
          })

          return {
            active: true,
            to,
            ignore_customers_orders,
            types,
            from_tolerance,
            from,
          } as unknown as RouteParamValue
        },
        manufacturer: toArrayOfInt,
        noTa: toBoolean,
        onlyZero: toBoolean,
        package_tolerance: toInt,
        order_package: (value) => {
          const [size, tolerance] = toArray(value)
          return { size, tolerance }
        },
        shuttlesDisabled: toObject,
        t2: toInt,
        t2_b: toInt,
        t2_split: toBoolean,
        t3: toInt,
        typeDAsC: toBoolean,
        include_new: toBoolean,
        typesFromDisabled: toObject,
        warehousesFromDisabled: toObject,
        warehousesToDisabled: toObject,
        warehouses_virtual: toObject,
        createdFrom: toInt,
        minBuffer: toInt,
        logistics_classes: toObject,
        sorts: (value) => {
          const [sort, dir, whSort] = toArray(value)
          return { sort, dir, whSort }
        },
        transfer_package: (value) => {
          const [size, tolerance] = toArray(value)
          return { size, tolerance }
        },
        lowMov: toBoolean,
        showCpa: toObject,
        routine: toInt,
        stock: (value) => {
          const stock = toObject<StockQuery>(value)

          return {
            active: true,
            stock_donator: mapValues(stock.d, (donator) =>
              mapValues(keyBy(donator, 0), (v) => v[1])
            ),
            stock_receptor: mapValues(stock.r, (donator) =>
              mapValues(keyBy(donator, 0), (v) => v[1])
            ),
          } as unknown as RouteParamValue
        },
        discounts: (value) => {
          const { g: global, c: classMerch } = toObject<DiscountQuery>(value)

          return {
            global,
            classMerch,
          } as unknown as RouteParamValue
        },
        use_five_thresholds: toBoolean,
        five_thresholds: (value) => {
          const data = toObject<FiveThresholdsConfig>(value)

          if (data.cdv_range[1] == null) {
            data.cdv_range[1] = undefined
          }

          return data as unknown as RouteParamValue
        },
      },
    },
    inject: {
      params: {
        step: 'general',
      },
    },
    encode: {
      query: {
        fabX: (value) => {
          const {
            active,
            types,
            from,
            from_tolerance,
            to,
            ignore_customers_orders,
          } = value as unknown as OrderBotFabXConfig

          if (!active) return undefined

          const query: OrderBotFabXQuery = {
            t: types,
            d: to,
            ico: ignore_customers_orders,
            l: from_tolerance,
            o: [],
          }

          forEach(from, (item, key) => {
            if (item.active)
              return query.o.push([
                key as unknown as WarehouseId,
                item.cpa,
                item.quantity,
              ])
          })

          return JSON.stringify(query)
        },
        order_package: (value) => {
          const { size, tolerance } = value as unknown as PackageSize

          return value
            ? JSON.stringify(Object.values({ size, tolerance }))
            : value
        },
        shuttlesDisabled: JSON.stringify,
        logistics_classes: JSON.stringify,
        sorts: (value) => {
          return value ? JSON.stringify(Object.values(value)) : value
        },
        typesFromDisabled: JSON.stringify,
        transfer_package: (value) => {
          const { size, tolerance } = value as unknown as PackageSize

          return value
            ? JSON.stringify(Object.values({ size, tolerance }))
            : value
        },
        warehousesFromDisabled: JSON.stringify,
        warehousesToDisabled: JSON.stringify,
        warehouses_virtual: JSON.stringify,
        showCpa: JSON.stringify,
        stock: (value) => {
          const { active, stock_donator, stock_receptor } =
            value as unknown as StockConfig

          if (!active) return undefined

          const d = mapValues(stock_donator, (types) =>
            map(types, (value, type) => [type, value])
          )
          const r = mapValues(stock_receptor, (types) =>
            map(types, (value, type) => [type, value])
          )

          return JSON.stringify({ d, r })
        },
        discounts: (value) => {
          const { global: g, classMerch: c } =
            value as unknown as DiscountConfig

          return JSON.stringify({ g, c })
        },
        five_thresholds: JSON.stringify,
      },
    },
  },
}

export default module
