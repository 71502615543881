import Auth from 'src/api/auth'
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import useI18n from 'src/hooks/i18n'
import useNotifications from 'src/hooks/notifications'
import { ActionTypes as AuthActionTypes } from 'src/types/stores/auth'
import { ActionTypes as CoreActionTypes } from 'src/types/stores/core'
import {
  CommonHeader,
  DownloadResponse,
  DownloadStream,
  ErrorResponse,
  ErrorValidationResponse,
  HeaderResponse,
  MessageResponse,
  Response,
} from 'src/types/api'
import { Device } from '@capacitor/device'
import { boot } from 'quasar/wrappers'
import { exportFile, Loading } from 'quasar'
import { useAuthStore } from 'src/stores/modules/auth'
import { useCoreStore } from 'src/stores/modules/core'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

//wrapper loader
Loading.show()

const headers = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-Response-Dispatch': '',
}

const api = axios.create({
  baseURL: process.env.API,
  headers,
  withCredentials: true,
})

function addCommonHeader(name: string, value: string): void {
  const headers = api.defaults.headers as unknown as CommonHeader
  headers.common[name] = value
  api.defaults.headers = headers
}

function downloadable(): Partial<AxiosRequestConfig> {
  return {
    responseType: 'arraybuffer',
    ...addDispatcher('download'),
  }
}

function download(response: DownloadResponse): DownloadStream {
  const fileContent = response.data

  const { headers } = response
  const fileName = headers['content-disposition'].replace(/.*filename=/, '')
  const mimeType = headers['content-type']

  return {
    fileContent,
    fileName,
    mimeType,
  }
}

function addDispatcher(value: string) {
  return { headers: { 'X-Response-Dispatch': value } }
}

//restore token in headers
const token: string = localStorage.getItem('token') || ''
if (token) {
  addCommonHeader('Authorization', `Bearer ${token}`)
}

function errorsRoutes(code: number) {
  return {
    403: 'forbidden',
    404: 'not_found',
  }[code]
}

export default boot(async ({ store, router }) => {
  const coreStore = useCoreStore(store)
  const authStore = useAuthStore(store)

  const { t } = useI18n()
  const { error, info, loading, loaded } = useNotifications()

  //LoadingBar by pendings
  api.interceptors.request.use(
    (config) => {
      coreStore[CoreActionTypes.ADD_AJAX_PENDING]()
      return config
    },
    (error) => {
      coreStore[CoreActionTypes.REMOVE_AJAX_PENDING]()
      return Promise.reject(error)
    }
  )

  //intercept / cast response globally
  api.interceptors.response.use(
    (response: AxiosResponse<Response> | DownloadResponse) => {
      const headers = response.config.headers as HeaderResponse
      const dispatcher = headers['X-Response-Dispatch']

      switch (dispatcher) {
        case 'message':
          const data = response.data as MessageResponse
          info(data.data.message)
          break
        case 'download':
          const downloadable = response as DownloadResponse
          const { fileName, mimeType, fileContent } = download(downloadable)
          exportFile(fileName, fileContent, { mimeType })
          break
      }

      coreStore[CoreActionTypes.REMOVE_AJAX_PENDING]()
      return (response.data || response) as Response
    },
    (errorMsg: AxiosError<ErrorResponse | ErrorValidationResponse>) => {
      coreStore[CoreActionTypes.REMOVE_AJAX_PENDING]()

      const response = errorMsg.response
      switch (response?.status) {
        case 401:
          //authentication required -> redirect to login
          localStorage.removeItem('token')
          localStorage.removeItem('token_encrypted')
          authStore[AuthActionTypes.SET_TOKEN]('')
          router.push({ name: 'login' }).catch(() => void 0)
          return
        case 403:
        case 404:
          router
            .push({ name: errorsRoutes(response?.status) })
            .catch(() => void 0)
          return
        case 419:
          // csrf renew
          const notification = loading({
            type: 'negative',
            message: response.data.message,
            caption: t('csrf_token_missmatch_renewing'),
          })

          Auth.csrf()
            .then(() => {
              loaded(
                notification,
                {
                  type: 'positive',
                  caption: t('csrf_token_missmatch_renewed'),
                },
                1
              )
            })
            .catch(() => void 0)
          return Promise.reject(errorMsg)
        case 422:
          response.data.message = t('wrong_submitted_data')
          break
      }

      if (response?.data?.redirect) {
        document.location.href = response.data.redirect
        return
      }

      const dataDispatch = response?.data || errorMsg
      error(dataDispatch.message)
      return Promise.reject(dataDispatch)
    }
  )

  //device id
  const { uuid } = await Device.getId()
  addCommonHeader('X-Dev-Uuid', uuid)

  // app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  // app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
})

export { api, addDispatcher, addCommonHeader, downloadable }
