import useRouteParser from 'src/hooks/routeParser'
import { RouteRecordRawArgumented } from 'src/types/route'

const { toArray, toArrayOfInt, toInt } = useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'order/:step?',
  name: 'orderBotOrder',
  component: () =>
    import(
      /* webpackChunkName: "order-bot-order" */ 'pages/order-bot/OrderBotOrder.vue'
    ),
  beforeEnter({ query }, _, next) {
    if (!query.order) {
      next({ name: 'forbidden' })
      return
    }
    next()
  },
  meta: {
    auth: {
      permission: 'order_bot_manage',
    },
    breadcrumb: {
      name: 'Genera ordini',
      icon: 'mdi-dolly',
    },
    casts: {
      query: {
        warehouse: (value) => toArrayOfInt(toArray(value)),
      },
      params: {
        step: toInt,
      },
    },
    encode: {
      query: {
        warehouse: JSON.stringify,
      },
    },
    inject: {
      params: {
        step: 1,
      },
    },
  },
}

export default module
