import { redirectToOldFrontEnd } from 'src/boot/proxy'
import { RouteRecordRawArgumented } from 'src/types/route'

const module: RouteRecordRawArgumented = {
  path: 'classes-merch',
  name: 'classesMerch',
  redirect() {
    redirectToOldFrontEnd('classes-merch')
    return {}
  },
  meta: {
    auth: {
      permission: 'classes_merch_access',
    },
    breadcrumb: {
      name: 'Classi',
      icon: 'mdi-format-list-group',
    },
  },

  children: [],
}

export default module
