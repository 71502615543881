import useI18n from 'src/hooks/i18n'
import useRouteParser from 'src/hooks/routeParser'
import { RouteRecordRawArgumented } from 'src/types/route'
import { toInteger } from 'lodash'

const { t } = useI18n()
const { getPaginationCasts, getPaginationInject, toArrayOfInt } =
  useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'clusters',
  name: 'classesMerchClusterIndex',
  component: () =>
    import(
      /* webpackChunkName: "class-merch-clusters-index" */ 'pages/class-merch/clusters/IndexPage.vue'
    ),
  meta: {
    auth: {
      permission: 'classes_merch_clusters_manage',
    },
    breadcrumb: {
      icon: 'mdi-land-rows-horizontal',
      name: t('breadcrumb.class_merch.clusters.index'),
    },
    inject: {
      query: {
        ...getPaginationInject(),
        sort: 'name',
        year: new Date().getFullYear(),
      },
    },
    casts: {
      query: {
        ...getPaginationCasts(),
        year: toInteger,
        class: toArrayOfInt,
      },
    },
    belongsTo: ['classesMerch'],
  },
  children: [
    {
      path: 'create',
      name: 'classesMerchClusterCreate',
      component: () =>
        import(
          /* webpackChunkName: "class-merch-clusters-create" */ 'pages/class-merch/clusters/CreatePage.vue'
        ),
      meta: {
        breadcrumb: {
          icon: 'mdi-plus',
          name: t('breadcrumb.class_merch.clusters.create'),
        },
      },
    },
    {
      path: ':id',
      name: 'classesMerchClusterShow',
      component: () =>
        import(
          /* webpackChunkName: "class-merch-clusters-show" */ 'pages/class-merch/clusters/ShowPage.vue'
        ),
      meta: {
        breadcrumb: {
          icon: 'mdi-atom-variant',
          name: t('breadcrumb.class_merch.clusters.show'),
        },
        casts: {
          params: {
            id: toInteger,
          },
        },
      },
      children: [
        {
          path: 'edit',
          name: 'classesMerchClusterEdit',
          component: () =>
            import(
              /* webpackChunkName: "class-merch-clusters-edit" */ 'pages/class-merch/clusters/EditPage.vue'
            ),
          meta: {
            breadcrumb: {
              icon: 'mdi-pencil',
              name: t('breadcrumb.class_merch.clusters.edit'),
            },
          },
        },
        {
          path: 'copy',
          name: 'classesMerchClusterCopy',
          component: () =>
            import(
              /* webpackChunkName: "class-merch-clusters-create" */ 'pages/class-merch/clusters/CreatePage.vue'
            ),
          meta: {
            breadcrumb: {
              icon: 'mdi-pencil',
              name: t('breadcrumb.class_merch.clusters.copy'),
            },
          },
        },
        {
          path: 'destroy',
          name: 'classesMerchClusterDestroy',
          component: () =>
            import(
              /* webpackChunkName: "class-merch-clusters-destroy" */ 'pages/class-merch/clusters/DestroyPage.vue'
            ),
          meta: {
            auth: {
              permission: 'classes_merch_clusters_master',
            },
          },
        },
      ],
    },
  ],
}

export default module
