import { defineStore } from 'pinia'
import { parseBool } from 'src/hooks/helpers'
import {
  ActionTypes,
  Actions,
  Getters,
  Namespace,
  State,
} from 'src/types/stores/drawer'

export const useDrawerStore = defineStore<Namespace, State, Getters, Actions>(
  'drawer',
  {
    state: () => ({
      open: parseBool(localStorage.getItem('drawer_open') || true),
      mini: parseBool(localStorage.getItem('drawer_mini') || false),
    }),
    getters: {},
    actions: {
      [ActionTypes.TOGGLE_MINI]() {
        this.mini = !this.mini
        localStorage.setItem('drawer_mini', this.mini.toString())
      },
      [ActionTypes.TOGGLE_OPEN]() {
        this.open = !this.open
        localStorage.setItem('drawer_open', this.open.toString())
      },
    },
  }
)
