import { LimitAndSortRequest } from 'src/types/api'
import { RouteParamValue } from 'src/types/route'

export default function useRouteParser() {
  function encode(value: RouteParamValue | RouteParamValue[]): string {
    return typeof value == 'string' ? value : JSON.stringify(value)
  }

  function toArrayOfInt(value: RouteParamValue | RouteParamValue[]): number[] {
    if (typeof value != 'object') {
      value = [value]
    }

    return (value as string[]).map((item) => parseInt(item))
  }

  function toBoolean(value: RouteParamValue | RouteParamValue[]): boolean {
    return value === true || value == 'true'
  }

  function toBooleanIndeterminable(
    value: RouteParamValue | RouteParamValue[]
  ): boolean | undefined {
    if (value === 'undefined') return undefined
    return toBoolean(value)
  }

  function toInt(value: RouteParamValue | RouteParamValue[]): number {
    return parseInt(value?.toString() ?? '')
  }

  function toFloat(value: RouteParamValue | RouteParamValue[]): number {
    return parseFloat(value?.toString() ?? '')
  }

  function toObject<T = Record<string, RouteParamValue>>(
    value: RouteParamValue | RouteParamValue[]
  ): T {
    return JSON.parse(value?.toString() ?? '') as T
  }

  function toArray<T = (string | number)[]>(
    value: RouteParamValue | RouteParamValue[]
  ): T {
    return JSON.parse(value?.toString() ?? '') as T
  }

  function getPaginationCasts() {
    return {
      limit: toInt,
      page: toInt,
    }
  }

  function getPaginationInject(): LimitAndSortRequest {
    return {
      limit: 25,
      page: 1,
      sort: 'id',
      dir: 'asc',
    }
  }

  return {
    getPaginationCasts,
    getPaginationInject,
    encode,
    toArray,
    toArrayOfInt,
    toBoolean,
    toBooleanIndeterminable,
    toObject,
    toInt,
    toFloat,
  }
}
