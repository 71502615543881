import { RouteRecordRawArgumented } from 'src/types/route'

//module definition
interface RouteModule {
  default: RouteRecordRawArgumented
}

//dynamic loads childrens modules
const requireContext: __WebpackModuleApi.RequireContext = require.context(
  './modules/',
  false,
  /\.mod.ts$/
)
const children: RouteRecordRawArgumented[] = requireContext
  .keys()
  .map((fileName: string) => {
    return requireContext<RouteModule>(fileName).default
  })

const routes: RouteRecordRawArgumented[] = [
  {
    path: '/',
    children,
    meta: {
      breadcrumb: {
        name: 'Home',
        icon: 'mdi-home-outline',
      },
    },
  },

  //server errors
  {
    name: 'server_error',
    path: '/server-error',
    component: () =>
      import(
        /* webpackChunkName: "server_error" */ 'pages/errors/Error500Page.vue'
      ),
  },

  //forbidden page
  {
    name: 'forbidden',
    path: '/forbidden',
    component: () =>
      import(
        /* webpackChunkName: "forbidden" */ 'pages/errors/Error403Page.vue'
      ),
  },

  //not found
  {
    name: 'not_found',
    path: '/:catchAll(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "not_found" */ 'pages/errors/Error404Page.vue'
      ),
  },
]

export default routes
