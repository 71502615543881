import { Device } from '@capacitor/device'
import { toString } from 'lodash'
import { boot } from 'quasar/wrappers'
import useGoogleAnalytics from 'src/hooks/googleAnalytics'
import { useAuthStore } from 'src/stores/modules/auth'
import { RouteLocationNormalizedLoadedArgumented } from 'src/types/route'

export default boot(({ router, store }) => {
  router.afterEach(async (to) => {
    const route = to as RouteLocationNormalizedLoadedArgumented
    const { event, track } = useGoogleAnalytics()

    //page view
    const name = route.meta.breadcrumb?.name ?? route.name ?? ''
    track(route.fullPath, toString(name))

    //user navigate
    const authStore = useAuthStore(store)
    event('navigate', {
      event_label: authStore.user.id,
      event_category: 'user',
    })

    //device id
    const { uuid } = await Device.getId()
    event('device', {
      event_label: uuid,
      event_category: 'uuid',
    })
  })
})
