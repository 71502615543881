import { _GettersTree } from 'pinia'
import { AuthData } from 'src/types/api/auth'

// namespace
export type Namespace = 'auth'

// state
export interface State {
  token: string
  user: AuthData
}

// actions
export enum ActionTypes {
  SET_TOKEN = 'setToken',
  SET_USER = 'setUser',
}
export interface Actions {
  [ActionTypes.SET_TOKEN]: (token: string) => void
  [ActionTypes.SET_USER]: (user?: AuthData) => void
}

// getters
export enum GetterTypes {
  IS_ADMIN = 'isAdmin',
  IS_AUTHENTICATED = 'isAuthenticated',
  USER_CAN = 'userCan',
}
export interface Getters extends _GettersTree<State> {
  [GetterTypes.IS_ADMIN]: (state: State) => boolean
  [GetterTypes.IS_AUTHENTICATED]: (state: State) => boolean
  [GetterTypes.USER_CAN]: (state: State) => (permission: string) => boolean
}
