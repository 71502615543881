import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'

const { t } = useI18n()

const module: RouteRecordRawArgumented = {
  path: 'products-sizes',
  name: 'productsSizes',
  redirect: () => ({ name: 'productsSizesIndex' }),
  children: [
    {
      path: ':step?',
      name: 'productsSizesIndex',
      component: () =>
        import(
          /* webpackChunkName: "products-sizes-index" */ 'pages/products-sizes/IndexPage.vue'
        ),
      meta: {
        breadcrumb: {
          name: t('breadcrumb.products_sizes.index'),
          icon: 'mdi-land-rows-horizontal',
        },
        auth: {
          permission: 'products_sizes_upload_measures',
        },
        inject: {
          params: {
            step: 'upload',
          },
        },
      },
    },
  ],
}

export default module