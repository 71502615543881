const breadcrumb = {
  add_f: 'Aggiungi nuova',
  add_m: 'Aggiungi nuovo',
  class_merch: {
    clusters: {
      index: 'Gestione cluster',
      create: 'Nuovo cluster',
      show: 'Dettagli cluster',
      edit: 'Modifica cluster',
      copy: 'Duplica cluster',
    },
  },
  order_bot: {
    compose: 'Order Bot',
    settings: 'Configurazione costi',
  },
  pfa_rules: {
    index: 'Premi fornitori',
    create: 'Nuovo premio',
    show: 'Dettagli premio',
    edit: 'Modifica premio',
    upload: 'Carica fatturati',
  },
  show: 'Dettaglio',
  suppliers: {
    index: 'Fornitori',
    show: 'Dettagli fornitore',
    edit: 'Modifica fornitore',
  },
  suppliers_promos: {
    index: 'Promozioni',
    create: 'Nuova promozione',
    show: 'Dettagli promozione',
    edit: 'Modifica promozione',
  },
  carbody: {
    compare: 'Confronta Prezzi',
    upload: 'Carica Prezzi',
    index: 'Carrozzeria',
    manage_products: 'Crea nuovo prodotto',
    edit_product: 'Modifica cod. CATI/ Inser. nuovo Fornitore',
    tmp_products_index: 'Nuovi prodotti',
  },
  products_sizes: {
    index: 'Dimensione Prodotti'
  },
  traders: {
    index: 'Componi prezzi Traders'
  },
  teccom: {
    index: 'Seleziona fornitore ordine'
  },
  update: 'Modifica',
}

export default breadcrumb