import useRouteParser from 'src/hooks/routeParser'
import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'

const { getPaginationCasts, toArrayOfInt } = useRouteParser()
const { t } = useI18n()

const module: RouteRecordRawArgumented = {
  path: 'settings/:action?',
  name: 'orderBotSettings',
  component: () =>
    import(
      /* webpackChunkName: "order-bot-settings" */ 'pages/order-bot/OrderBotSettings.vue'
    ),
  meta: {
    auth: {
      permission: 'order_bot_manage',
    },
    breadcrumb: {
      name: t('breadcrumb.order_bot.settings'),
      path: '/order-bot/settings',
      icon: 'mdi-store-cog',
    },
    casts: {
      query: {
        class: toArrayOfInt,
        ...getPaginationCasts(),
      },
    },
  },
}

export default module
