import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'
import useRouteParser from 'src/hooks/routeParser'
const { getPaginationCasts, toArrayOfInt } = useRouteParser()

const { t } = useI18n()

const module: RouteRecordRawArgumented = {
  path: 'teccom',
  name: 'teccom',
  redirect: () => ({ name: 'tecComIndex' }),
  children: [
    {
      path: ':step?',
      name: 'tecComIndex',
      component: () =>
        import(
          /* webpackChunkName: "teccom-index" */ 'pages/teccom/IndexPage.vue'
        ),
      meta: {
        breadcrumb: {
          name: t('breadcrumb.teccom.index'),
          icon: 'mdi-package-variant',
        },
        auth: {
          permission: 'teccom_submit_orders',
        },
        inject: {
          params: {
            step: 'select',
          },
          query: {
            sort: 'tof_datord',
            dir: 'desc',
          }
        },
        casts: {
          query: {
            ...getPaginationCasts(),
            supplier_id: toArrayOfInt,
          },
        },
      },
    },
  ],
}

export default module