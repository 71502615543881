import { _GettersTree } from 'pinia'

// namespace
export type Namespace = 'drawer'

// state
export interface State {
  mini: boolean
  open: boolean
}

// actions
export enum ActionTypes {
  TOGGLE_OPEN = 'toggleOpen',
  TOGGLE_MINI = 'toggleMini',
}
export interface Actions {
  [ActionTypes.TOGGLE_OPEN]: () => void
  [ActionTypes.TOGGLE_MINI]: () => void
}

// getters
export enum GetterTypes {}
export type Getters = _GettersTree<State>
