interface WindowGtag {
  gtag: (
    group: string,
    action: string,
    data: Record<string, string | number | undefined>
  ) => void
}

export default function useGoogleAnalytics() {
  //tracking id
  const tracking_id: string | undefined = process.env.GA_TRACK_ID

  function track(page_path: string, page_name: string | undefined): void {
    if (tracking_id == undefined) {
      console.error('Google analytics not loaded')
      return
    }

    ;(window as unknown as WindowGtag).gtag('config', tracking_id, {
      page_path,
      page_name,
    })
  }

  function event(
    action: string,
    data: Record<string, string | number> = {}
  ): void {
    if (tracking_id == undefined) {
      console.error('Google analytics not loaded')
      return
    }

    ;(window as unknown as WindowGtag).gtag('event', action, data)
  }

  return {
    track,
    event,
  }
}
