import { api } from 'src/boot/axios'
import { SettingApiResponse } from 'src/types/api/setting'

const END_POINT = 'settings'

const Setting = {
  index: async (): Promise<SettingApiResponse> => {
    return await api.get(END_POINT)
  },
}

export default Setting
