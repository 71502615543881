const pfa = {
  cati_forecast_reward_value: 'Cati Tot. Previsione',
  cati_revenue: 'Previsione fatturato fine anno CATI',
  cati_revenue_short: 'Prev. CATI',
  cati_reward_value: 'Cati Tot. Premio',
  gaui_reward_value: 'Gaui Tot. Premio',
  confirm_remove_rule: 'Eliminare il premio fornitore "{label}"?',
  gaui_revenue: 'Previsione fatturato fine anno GAUI',
  gaui_revenue_short: 'Prev. GAUI',
  groupauto_reward_value: 'GAuto Tot. Premio',
  new_rule: 'Nuovo premio',
  new_rule_tooltip: 'Aggingi un nuovo premio fornitore',
  progress_month: 'Mese aggiornamento',
  purchase_budget_month: 'Seleziona il mese',
  revenue: 'Fatturato progressivo',
  revenue_short: 'Fatt. Progr.',
  reward: 'Premio',
  reward_add: 'Aggiungi un nuovo premio "{label}"',
  reward_destroy: 'Rimuovi il premio',
  reward_destroyed: 'Premio eliminato',
  reward_value: 'Tot. Premio',
  reward_value_type: 'Premi in euro',
  rule_definition: 'Definizione regola',
  rule_revenues: 'Avanzamento progressivo',
  rule_rewards: 'Definizione premi',
  target: 'Obiettivo',
  target_destroy: "Rimuovi l'obiettivo",
  target_destroyed: 'Obiettivo eliminato',
  target_scored: 'Obiettivo centrato',
  target_add: 'Aggiungi un obiettivo',
  unity: {
    CUR: 'Euro',
    PZ: 'Pezzi',
    LT: 'Litri',
  },
  upload_revenue_button_label: 'Carica fatturati',
  upload_revenue_button_title: "Carica il file con l'avanzamento dei fatturati",
  upload_missed: 'Alcune regole inserite non sono state trovate',
}

export default pfa
