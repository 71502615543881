import script from "./BreadCrumb.vue?vue&type=script&setup=true&lang=ts"
export * from "./BreadCrumb.vue?vue&type=script&setup=true&lang=ts"

import "./BreadCrumb.vue?vue&type=style&index=0&id=16b35450&lang=scss"

const __exports__ = script;

export default __exports__
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPageSticky,QToolbar,QBreadcrumbs,QBreadcrumbsEl});
