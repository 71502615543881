import common from './modules/common/it'
import class_merch from './modules/class_merch/it'
import breadcrumb from './modules/breadcrumb/it'
import order_bot from './modules/order_bot/it'
import product from './modules/product/it'
import pfa from './modules/pfa/it'
import sidebar from './modules/sidebar/it'
import supplier from './modules/supplier/it'
import carbody from './modules/carbody/it'
import carsdata from './modules/carsdata/it';
import products_sizes from './modules/products_sizes/it'
import traders from './modules/traders/it'
import teccom from './modules/teccom/it'

export default {
  ...common,
  class_merch,
  breadcrumb,
  order_bot,
  pfa,
  product,
  sidebar,
  supplier,
  carbody,
  carsdata,
  products_sizes,
  traders,
  teccom
}
