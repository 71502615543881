const traders = {
    steps: {
        select: 'Seleziona i parametri prezzo',
        validate: 'Valida dati',
        send: 'Invia'
    },
    index: 'Componi prezzi Traders',
    select_type_filter: 'Seleziona tipo filtri',
    select_type_filter_hint: 'Filtra il tipo di elementi per cui vuoi costruire il prezzo',
    select_filter: 'Seleziona filtri',
    select_filter_hint: 'Filtra gli elementi per cui vuoi costruire il prezzo',
    group: 'Nei gruppi',
    family: 'Nelle famiglie',
    manufacturer: 'Nei produttori',
    class: 'Nelle classi',
    product: 'Nei prodotti',
    manufacturer_select: 'Seleziona produttore',
    family_select: 'Seleziona famiglia di prodotti',
    class_select: 'Seleziona la classe mercelogica',
    group_select: 'Seleziona il gruppo di prodotti',
    in_warehouses: 'Seleziona magazzini',
    apply_discount: 'Applica maggiorazione in percentuale',
    label_discount: 'Seleziona maggiorazione',
    apply_threshold: 'Imposta soglia giacenza minima',
    label_threshold: 'Seleziona soglia',
    id: 'ID',
    cati_code: 'Codice CATI',
    threshold: 'Soglia',
    net_net: 'Net Net',
    cmp: 'CMP',
    cmp_net_net: 'CMP - PFA',
    price_net: 'Costo da utilizzare',
    price_gross: 'Prezzo finale',
    increase: 'Maggiorazione',
    manufacturer_name: 'Produttore',
    manufacturer_id: 'Codice produttore',
    availability: 'Disponibilita',
    data_send_text1: 'Sono stati trovati',
    data_send_text2: ' con i criteri di ricerca usati.',
    data_send_title: 'Esportali in un file excel utilizzando il pulsante "Scarica Listino"',
    download: 'Scarica Listino'
}

export default traders
