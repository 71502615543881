import Auth from 'src/api/auth'
import Setting from 'src/api/setting'
import { ActionTypes as AuthActionTypes } from 'src/types/stores/auth'
import { ActionTypes as CoreActionTypes } from 'src/types/stores/core'
import { Loading } from 'quasar'
import { boot } from 'quasar/wrappers'
import { useAuthStore } from 'src/stores/modules/auth'
import { useCoreStore } from 'src/stores/modules/core'

export default boot(async ({ store }) => {
  const authStore = useAuthStore(store)
  const coreStore = useCoreStore(store)

  //set user
  const user = await Auth.user().catch(() => void 0)
  authStore[AuthActionTypes.SET_USER](user?.data)

  //set golbal settings
  const settings = await Setting.index().catch(() => void 0)
  coreStore[CoreActionTypes.SET_SETTINGS](settings?.data)

  //set ready
  coreStore[CoreActionTypes.SET_READY]()

  //hide wrapper
  if (coreStore.ready) {
    Loading.hide()
  }
})
