import { redirectToOldFrontEnd } from 'src/boot/proxy'
import { RouteRecordRawArgumented } from 'src/types/route'

const module: RouteRecordRawArgumented = {
  path: 'login',
  name: 'login',
  component: () =>
    import(/* webpackChunkName: "login" */ 'pages/LoginPage.vue'),
  meta: {
    guest: true,
  },
  beforeEnter: (route) => redirectToOldFrontEnd(route.fullPath),
}

export default module
