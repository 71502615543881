import { redirectToOldFrontEnd } from 'src/boot/proxy'
import { RouteRecordRawArgumented } from 'src/types/route'

const module: RouteRecordRawArgumented = {
  path: 'q-bot',
  name: 'qBot',
  redirect: () => {
    redirectToOldFrontEnd('/q-bot/revenues')
    return { name: 'Home' }
  },
}

export default module
