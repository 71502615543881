import useConfirmDialog from 'src/hooks/confirmDialog'
import useI18n from 'src/hooks/i18n'
import { ActionTypes } from 'src/types/stores/core'
import { GetterTypes } from 'src/types/stores/auth'
import { LoadingBar } from 'quasar'
import { RouteLocationNormalized } from 'vue-router'
import { RouteLocationNormalizedArgumented } from 'src/types/route'
import { boot } from 'quasar/wrappers'
import { forEach } from 'lodash'
import { useAuthStore } from 'src/stores/modules/auth'
import { useCoreStore } from 'src/stores/modules/core'

export default boot(({ router, store }) => {
  const { t } = useI18n()
  const coreStore = useCoreStore(store)
  const authStore = useAuthStore(store)

  router.beforeEach((to, _, next) => {
    const route = to as RouteLocationNormalizedArgumented

    function resolve() {
      if (route.meta.old) {
        document.location.replace(
          `${String(process.env.OLD_URI)}${String(route.meta.old)}`
        )
        return
      }

      //progress start
      if (!coreStore.ajaxLoading && !LoadingBar.isActive) LoadingBar.start()

      const isGuestRequest = route.matched.some((record) => record.meta.guest)

      //authenthicated check
      if (authStore[GetterTypes.IS_AUTHENTICATED]) {
        //guest only redirect
        if (isGuestRequest) {
          next({ name: 'index' })
          return
        }

        //recursive grant check
        const isNotAuthorized = route.matched.some((record) => {
          const route = record as unknown as RouteLocationNormalizedArgumented

          if (!route.meta.auth) return false

          const { permission } = route.meta.auth

          return !!permission && !authStore[GetterTypes.USER_CAN](permission)
        })

        if (isNotAuthorized) {
          next({ name: 'forbidden' })
          return
        }
      } else if (!isGuestRequest) {
        //unautethicated blocking auth required
        next({ name: 'login' })
        return
      }

      // inject missed data if missed
      let haveToInject = false
      if (route.meta.inject !== undefined) {
        const { params, query } = route.meta.inject
        if (params != undefined) {
          forEach(params, (value, name) => {
            if (!route.params[name]) {
              route.params[name] = value
              haveToInject = true
            }
          })
        }
        if (query != undefined) {
          forEach(query, (value, name) => {
            if (!route.query[name]) {
              route.query[name] = value
              haveToInject = true
            }
          })
        }
      }

      if (haveToInject) {
        next(route as RouteLocationNormalized)
        return
      }

      next()
    }

    if (coreStore.dirty) {
      useConfirmDialog()
        .show({
          message: t('unsaved_message'),
          title: t('warning'),
        })
        .onOk(() => {
          coreStore[ActionTypes.SET_DIRTY](false)
          resolve()
        })
      return
    }

    resolve()
  })

  //stop loading bar
  router.afterEach((to) => {
    const route = to as RouteLocationNormalizedArgumented

    //casts
    if (route.meta.casts !== undefined) {
      const { params, query } = route.meta.casts
      if (params != undefined) {
        forEach(params, (method, name) => {
          if (route.params[name] != undefined && route.params[name] != null) {
            route.params[name] = method(route.params[name])
          }
        })
      }
      if (query != undefined) {
        forEach(query, (method, name) => {
          if (route.query[name] != undefined && route.query[name] != null) {
            route.query[name] = method(route.query[name])
          }
        })
      }
    }

    if (!coreStore.ajaxLoading && LoadingBar.isActive) LoadingBar.stop()
  })
})
