import { defineStore } from 'pinia'
import {
  ActionTypes,
  Actions,
  GetterTypes,
  Getters,
  Namespace,
  State,
} from 'src/types/stores/auth'

export const useAuthStore = defineStore<Namespace, State, Getters, Actions>(
  'auth',
  {
    state: () => ({
      token: '',
      user: <State['user']>{},
    }),
    getters: {
      [GetterTypes.IS_ADMIN](state) {
        return state.user.is_admin
      },
      [GetterTypes.IS_AUTHENTICATED](state) {
        return !!state.user.id
      },
      [GetterTypes.USER_CAN](state) {
        return (permission: string) =>
          this[GetterTypes.IS_AUTHENTICATED] &&
          (this[GetterTypes.IS_ADMIN] ||
            state.user.permissions.includes(permission))
      },
    },
    actions: {
      [ActionTypes.SET_USER](user) {
        this.user = user ?? <State['user']>{}
      },
      [ActionTypes.SET_TOKEN](token) {
        this.token = token
      },
    },
  }
)
