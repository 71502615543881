import useI18n from 'src/hooks/i18n'
import { Notify } from 'quasar'

type NotifyInstance = ReturnType<Notify['create']>
type NotifyArguments = Exclude<Parameters<Notify['create']>[0], string>

export default function useNotifications(opts: NotifyArguments = {}) {
  const { t } = useI18n()

  const defaultOptions = {
    type: 'positive',
    icon: 'mdi-check-bold',
    progress: true,
    group: false,
    timeout: 5000,
    spinner: false,
    actions: [{ icon: 'mdi-close', color: 'white' }],
    ...opts,
  }

  function toObjectWithDefaultMessage(
    data?: string | NotifyArguments,
    defaultMessage?: string
  ): NotifyArguments {
    // undefined, use provided default as i18n value
    if (data === undefined) {
      return { message: defaultMessage ?? '' }
    }

    // is string convert to object
    if (typeof data == 'string') {
      return { message: data }
    }

    return data
  }

  function getConfig(data: NotifyArguments): NotifyArguments {
    const config = {
      ...defaultOptions,
      ...data,
    }

    // if has timeout, remove actions
    if (config.timeout > 0) {
      config.actions = []
    }

    return config
  }

  function notify(data: NotifyArguments): NotifyInstance {
    return Notify.create(getConfig(data))
  }

  function loading(data?: string | NotifyArguments): NotifyInstance {
    return notify({
      timeout: 0,
      spinner: true,
      ...toObjectWithDefaultMessage(data, 'loading'),
    })
  }

  function loaded(
    notification: NotifyInstance,
    data?: string | NotifyArguments,
    delay = 0
  ): void {
    setTimeout(() => {
      notification(getConfig(toObjectWithDefaultMessage(data)))
    }, delay * 1000)
  }

  function success(data?: string | NotifyArguments): NotifyInstance {
    return notify({
      ...toObjectWithDefaultMessage(data, t('completed')),
    })
  }

  function error(data?: string | NotifyArguments): NotifyInstance {
    return notify({
      type: 'negative',
      icon: 'mdi-close-octagon',
      ...toObjectWithDefaultMessage(data, t('generic_error')),
    })
  }

  function info(data: string | NotifyArguments): NotifyInstance {
    return notify({
      type: 'info',
      icon: 'mdi-alert-circle-outline',
      ...toObjectWithDefaultMessage(data),
    })
  }

  function warning(data: string | NotifyArguments): NotifyInstance {
    return notify({
      type: 'warning',
      icon: 'mdi-alert-outline',
      ...toObjectWithDefaultMessage(data),
    })
  }

  return {
    error,
    info,
    loaded,
    loading,
    notify,
    success,
    warning,
  }
}
