import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'

// submodules
import compose from 'src/router/modules/order-bot/compose'
import order from 'src/router/modules/order-bot/order'
import settings from 'src/router/modules/order-bot/settings'

const { t } = useI18n()

const module: RouteRecordRawArgumented = {
  path: 'order-bot',
  name: 'orderBot',
  redirect() {
    return { name: 'orderBotCompose' }
  },
  meta: {
    auth: {
      permission: 'order_bot_access',
    },
    breadcrumb: {
      name: t('breadcrumb.order_bot.compose'),
      icon: 'mdi-package-variant',
    },
  },

  children: [compose, settings, order],
}

export default module
