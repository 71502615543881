import en from 'src/i18n/en'
import it from 'src/i18n/it'
import { boot } from 'quasar/wrappers'
import { createI18n } from 'vue-i18n'

// Create I18n instance
export const i18n = createI18n({
  legacy: false,
  locale: process.env.DEFAULT_LANG,
  messages: {
    it,
    'en-US': en,
  },
})

export default boot(({ app }) => {
  app.use(i18n)
})
