import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'
import useRouteParser from 'src/hooks/routeParser'
import { toInteger } from 'lodash'

const { t } = useI18n()
const { getPaginationCasts, getPaginationInject, toArrayOfInt } =
  useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'suppliers-promos',
  name: 'supplierPromo',
  redirect: () => ({ name: 'supplierPromoIndex' }),
  meta: {
    auth: {
      permission: 'supplier_promo_show',
    },
    belongsTo: ['suppliers'],
  },

  children: [
    {
      path: '',
      name: 'supplierPromoIndex',
      component: () =>
        import(
          /* webpackChunkName: "supplier-promo-index" */ 'pages/supplier/promo/IndexPage.vue'
        ),
      meta: {
        breadcrumb: {
          name: t('breadcrumb.suppliers_promos.index'),
          icon: 'mdi-land-rows-horizontal',
        },
        inject: {
          query: {
            ...getPaginationInject(),
            year: new Date().getFullYear(),
          },
        },
        casts: {
          query: {
            ...getPaginationCasts(),
            supplier: toArrayOfInt,
            year: toInteger,
          },
        },
      },
      children: [
        {
          path: 'create',
          name: 'supplierPromoCreate',
          component: () =>
            import(
              /* webpackChunkName: "supplier-promo-create" */ 'pages/supplier/promo/CreatePage.vue'
            ),
          meta: {
            auth: {
              permission: 'supplier_promo_manage',
            },
            breadcrumb: {
              icon: 'mdi-plus',
              name: t('breadcrumb.suppliers_promos.create'),
            },
          },
        },
        {
          path: ':id',
          name: 'supplierPromoShow',
          component: () =>
            import(
              /* webpackChunkName: "supplier-promo-show" */ 'pages/supplier/promo/ShowPage.vue'
            ),
          redirect: () => ({ name: 'supplierPromoEdit' }),
          meta: {
            breadcrumb: {
              icon: 'mdi-bullhorn-outline',
              name: t('breadcrumb.suppliers_promos.show'),
            },
            casts: {
              params: {
                id: toInteger,
              },
            },
          },
          children: [
            {
              path: 'edit',
              name: 'supplierPromoEdit',
              component: () =>
                import(
                  /* webpackChunkName: "supplier-promo-edit" */ 'pages/supplier/promo/EditPage.vue'
                ),
              meta: {
                breadcrumb: {
                  icon: 'mdi-pencil',
                  name: t('breadcrumb.suppliers_promos.edit'),
                },
              },
            },
            {
              path: 'destroy',
              name: 'supplierPromoDestroy',
              component: () =>
                import(
                  /* webpackChunkName: "supplier-promo-destroy" */ 'pages/supplier/promo/DestroyPage.vue'
                ),
              meta: {
                auth: {
                  permission: 'supplier_promo_manage',
                },
              },
            },
          ],
        },
      ],
    },
  ],
}

export default module