import script from "./AppHeader.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppHeader.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QBtn,QTooltip});
