// import { boot } from 'quasar/wrappers'
// import { Component } from 'vue'

// interface ComponentModule {
//   default: Component
// }

// export default boot(({ app }) => {
//   const requireContext: __WebpackModuleApi.RequireContext = require.context(
//     '../components/common/',
//     true,
//     /\.vue$/
//   )

//   requireContext.keys().forEach((fileName: string) => {
//     const componentName = fileName.replace(/.*\/(.*).vue$/, '$1')

//     app.component(
//       componentName,
//       requireContext<ComponentModule>(fileName).default
//     )
//   })
// })
