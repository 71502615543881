import { redirectToOldFrontEnd } from 'src/boot/proxy'
import { RouteRecordRawArgumented } from 'src/types/route'

const module: RouteRecordRawArgumented = {
  path: 'x-bot',
  name: 'xBot',
  redirect: () => {
    redirectToOldFrontEnd('/x-bot/import')
    return { name: 'Home' }
  },
}

export default module
