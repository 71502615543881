//authentication proxy between old and new frontend

const params = new URLSearchParams(document.location.search.substring(1))

const token = params.get('token')
const uuid = params.get('uuid')

//if token exist set
if (!!token) {
  localStorage.setItem('token', `Encrypted ${token}`)
}

//if device exists set
if (!!uuid) {
  localStorage.setItem('_capuid', uuid)
}

//if some params redirect
if (!!uuid || !!token) {
  ;['token', 'uuid', 'fake'].forEach((param) => {
    params.delete(param)
  })

  const { host, pathname } = document.location
  document.location.replace(`//${host}${pathname}?${params.toString()}`)
}

export function redirectToOldFrontEnd(uri = '', replace = false): boolean {
  //strip first slash if exists
  if (uri[0] == '/') uri = uri.substring(1)

  const newUri = `${String(process.env.OLD_URI)}${uri}`

  if (replace) document.location.replace(newUri)
  else document.location.href = newUri

  return false
}
