const products_sizes = {
  steps: {
    upload: 'Carica file',
    validate: 'Valida dati',
    send: 'Invia',
  },
  package_type_label: 'Seleziona confezione',
  weight_type_label: 'Seleziona pesata',
  package_type_hint: 'Seleziona tipo confezione',
  weight_type_hint: 'Seleziona tipo pesata',
  file_hint: 'Carica file misure',
  source_type_label: 'Seleziona la fonte',
  source_type_hint: 'Seleziona fonte misurazioni',
  file_loaded_hint: 'File caricato',
  product_id: 'ID Prodotto',
  manufacturer_name: 'Produttore',
  manufacturer_code: 'Codice produttore',
  dim_min: 'Dim. minima',
  dim_mid: 'Dim. mediana',
  dim_max: 'Dim. massima',
  weight: 'Peso',
  volume: 'Volume',
  weight_type: 'Tipo pesata',
  package_type: 'Tipo confenzione',
  description: 'Descrizione',
  reason: 'Status',
  lev: 'Levensthein',
  export_ko: 'Esporta KO',
  description_placeholder:
    'Descrivi il file delle misurazioni (max 255 caratteri)',
  data_send_title: 'Stai inviando questi dati',
  source: 'Fonte misurazioni',
  file_name: 'Nome file caricato',
  items_num: 'Numero misurazioni',
  valid_items_num: 'Misurazioni valide',
  main_title: 'Carica dati pesi e misure prodotti',
  main_text:
    'Puoi caricare qui il file pesi e misure di cui puoi trovare un template in formato .csv in fondo alla sezione.',
  main_text1:
    'Dopo aver selezionato il tipo di codice, potrai caricare il file e selezionare la fonte; potrai poi accedere al passo successivo dove troverai la tabella riassuntiva delle validazioni. A questo punto potrai verificare gli esiti e scaricare la lista dei KO per la revisione dei dati.',
  main_text2:
    "All'ultimo passo potrai verificare gli ultimi dati, inserire una descrizione del file e inviare al webservice i dati raccolti.",
  legenda1: 'Qui sotto trovi la legenda degli esiti:',
  legenda2: 'Qui sotto trovi la legenda dei tipi di confezione:',
  legenda3: 'Qui sotto trovi la legenda dei tipi di pesata:',
  download_template: 'Scarica il file example-measures.csv',
  select_code_type_hint: 'Seleziona il tipo di codice prodotto',
  select_code_type_label: 'Seleziona tipo codice',
  code_type: 'Tipo codice',
  measures_units: 'Le unità di misura che devono essere utilizzate sono:',
  measure_units_legenda1:
    'Dim. minima, dim. mediana e dim. massima: centimetri',
  measure_units_legenda2: 'Volume: decimetri cubi',
  measure_units_legenda3: 'Peso: chilogrammi',
  example_file: 'Nel file scaricabile qui sotto potrai trovare i nomi delle colonne da utilizzare per il caricamento dei file. Tieni presente che il campo "ManufacturerName" può assumere il valore "TO" (se il file proviene da una pesata), "Bosch" (se proviene da un produttore) etc; analogamente il campo "Manufacturer Code" può essere valorizzato con un codice EAN, il codice del produttore o il Codice CATI, a seconda della provenienza.',
}

export default products_sizes