import { merge } from 'lodash'
import { Dialog, DialogChainObject, QDialogOptions } from 'quasar'
import useI18n from 'src/hooks/i18n'

export default function useConfirmDialog(defaultOptions: QDialogOptions = {}) {
  const { t } = useI18n()

  // options
  const options: QDialogOptions = merge(
    {
      title: t('confirm_requested'),
      message: t('confirm_requested_message'),
      cancel: {
        color: 'dark',
        flat: true,
        label: t('undo'),
      },
      ok: {
        color: 'primary',
        flat: true,
        label: t('continue'),
      },
      persistent: true,
    },
    defaultOptions
  )

  function show(customOptions: QDialogOptions = {}): DialogChainObject {
    return Dialog.create(merge(options, customOptions))
  }

  return {
    options,
    show,
  }
}
