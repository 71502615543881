import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'
import useRouteParser from 'src/hooks/routeParser'
import { toInteger } from 'lodash'

const { t } = useI18n()
const { getPaginationCasts, getPaginationInject, toArrayOfInt } =
  useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'pfa-rules',
  name: 'pfaRules',
  redirect: () => ({ name: 'pfaRulesIndex' }),
  meta: {
    auth: {
      permission: 'pfa_show',
    },
    belongsTo: ['suppliers'],
  },

  children: [
    {
      path: '',
      name: 'pfaRulesIndex',
      component: () =>
        import(
          /* webpackChunkName: "pfa-rules-index" */ 'pages/pfa/rules/IndexPage.vue'
        ),
      meta: {
        breadcrumb: {
          name: t('breadcrumb.pfa_rules.index'),
          icon: 'mdi-land-rows-horizontal',
        },
        inject: {
          query: {
            ...getPaginationInject(),
            year: new Date().getFullYear(),
          },
        },
        casts: {
          query: {
            ...getPaginationCasts(),
            supplier: toArrayOfInt,
            year: toInteger,
          },
        },
      },
      children: [
        {
          path: 'upload',
          name: 'pfaRulesUpload',
          component: () =>
            import(
              /* webpackChunkName: "pfa-rules-upload" */ 'pages/pfa/rules/UploadPage.vue'
            ),
          meta: {
            auth: {
              permission: 'pfa_manage',
            },
            breadcrumb: {
              icon: 'mdi-upload',
              name: t('breadcrumb.pfa_rules.upload'),
            },
          },
        },
        {
          path: 'create',
          name: 'pfaRulesCreate',
          component: () =>
            import(
              /* webpackChunkName: "pfa-rules-create" */ 'pages/pfa/rules/CreatePage.vue'
            ),
          meta: {
            auth: {
              permission: 'pfa_manage',
            },
            breadcrumb: {
              icon: 'mdi-plus',
              name: t('breadcrumb.pfa_rules.create'),
            },
          },
        },
        {
          path: ':id',
          name: 'pfaRulesShow',
          redirect: () => ({ name: 'pfaRulesEdit' }),
          component: () =>
            import(
              /* webpackChunkName: "pfa-rules-show" */ 'pages/pfa/rules/ShowPage.vue'
            ),
          meta: {
            breadcrumb: {
              icon: 'mdi-trophy-award',
              name: t('breadcrumb.pfa_rules.show'),
            },
            casts: {
              params: {
                id: toInteger,
              },
            },
          },
          children: [
            {
              path: 'edit',
              name: 'pfaRulesEdit',
              component: () =>
                import(
                  /* webpackChunkName: "pfa-rules-edit" */ 'pages/pfa/rules/EditPage.vue'
                ),
              meta: {
                breadcrumb: {
                  icon: 'mdi-pencil',
                  name: t('breadcrumb.pfa_rules.edit'),
                },
              },
            },
            {
              path: 'destroy',
              name: 'pfaRulesDestroy',
              component: () =>
                import(
                  /* webpackChunkName: "pfa-rules-destroy" */ 'pages/pfa/rules/DestroyPage.vue'
                ),
              meta: {
                auth: {
                  permission: 'pfa_manage',
                },
              },
            },
          ],
        },
      ],
    },
  ],
}

export default module