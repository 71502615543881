import { _GettersTree } from 'pinia'
import { SettingData } from 'src/types/api/setting'

// namespace
export type Namespace = 'core'

// state
export interface State {
  ajaxLoading: boolean
  ajaxPendings: number
  dirty: boolean
  ready: boolean
  settings?: SettingData
}

// actions
export enum ActionTypes {
  ADD_AJAX_PENDING = 'addAjaxPending',
  REMOVE_AJAX_PENDING = 'removeAjaxPending',
  SET_DIRTY = 'setDirty',
  SET_READY = 'setReady',
  SET_SETTINGS = 'setSettings',
}
export interface Actions {
  [ActionTypes.ADD_AJAX_PENDING]: () => void
  [ActionTypes.REMOVE_AJAX_PENDING]: () => void
  [ActionTypes.SET_DIRTY]: (dirty: boolean) => void
  [ActionTypes.SET_READY]: () => void
  [ActionTypes.SET_SETTINGS]: (settings?: SettingData) => void
}

// getters
export enum GetterTypes {}
export type Getters = _GettersTree<State>
