import common from './modules/common/en'
import class_merch from './modules/class_merch/en'
import breadcrumb from './modules/breadcrumb/en'
import order_bot from './modules/order_bot/en'
import product from './modules/product/en'
import pfa from './modules/pfa/en'
import sidebar from './modules/sidebar/en'
import supplier from './modules/supplier/en'

export default {
  ...common,
  class_merch,
  breadcrumb,
  order_bot,
  product,
  pfa,
  sidebar,
  supplier,
}
