import script from "./DrawerItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./DrawerItem.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QExpansionItem,QItem,QItemSection,QIcon});qInstall(script, 'directives', {Ripple});
